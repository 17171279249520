<script lang="ts">
	import classNames from "classnames";
	import type { SvelteComponent } from "svelte";

  /**
   * Specify the size of the icon
   * @type string
   */
  export let size: "full" | "default" | "small" | "middle" | "large" | "xl" = "default";

  /**
   * Specify the icon name
   * @type string
   */
  export let icon: string;

  /**
   * Specify if the icon should be circled
   * @type boolean
   */
  export let circled: boolean = false;

  const iconSizes = {
    full: "100%",
    default: 24,
    small: 16,
    middle: 20,
    large: 32,
    xl: 36
  }

  /**
   * Icon component
   * @type ConstructorOfATypedSvelteComponent
   */
  let iconComponent: typeof SvelteComponent;

  const getIconComponent = async (icon: string) => {
    iconComponent = (await import(`./icons/${icon}.svelte`)).default;
  };

  getIconComponent(icon.replace(/(^\w|-\w)/g, (str) => str.replace('-', '').toUpperCase()));

  $: iconSize = iconSizes[size]
  let iconClass = `size-${size}`;
</script>

<div
  class={classNames('icon', iconClass)}
  class:circled={circled}
>
  <svelte:component
    this={iconComponent}
    {...$$restProps}
    width={iconSize}
    height={iconSize}
    fill="currentColor"
    on:click
    on:mouseover
    on:focus
    on:mouseenter
    on:mouseleave
  />
</div>

<style type="text/scss" lang="scss" scoped>
  .icon {
    @apply relative;
    @apply inline-flex;
    @apply items-center justify-center;

    &.circled {
      @apply rounded-full;
      @apply bg-gray-300;
      @apply p-2;
    }

    &.size-full {
      @apply w-full h-full;
    }

    &.size-default {
      @apply w-6 h-6;
    }

    &.size-small {
      @apply w-4 h-4;
    }

    &.size-middle {
      @apply w-5 h-5;
    }

    &.size-large {
      @apply w-8 h-8;
    }

    &.size-xl {
      @apply w-10 h-10;
    }
  }

	:global(.icon) {
		& svg {
			@apply w-full h-full;
			@apply transition-colors;
		}
	}
</style>